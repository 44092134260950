
    @if(registered || data.confirm_mode){
      <form class="form-confirm" [formGroup]="formConfirm" (submit)="confirm()" method="post">
        <div style="padding-bottom: 1em;">
          <p style="font-family: Montserrat; ">
            Please enter the code sent to your email to confirm your account or resend another one
          </p>
          <div style="display: flex; flex-wrap: wrap; gap: 0.5em;" id="codes-container">
            @for(c of codes ; track $index){
              <input (click)="cleanCode($index)" (paste)="pasteCode($event)" (input)="validateCode($event,$index)" class="code-class" type="number" formControlName="code{{$index}}">
            }

            <button type="button" (click)="resend()" mat-icon-button matToolTip="Resend code">
              <mat-icon>restart_alt</mat-icon>
            </button>
          </div>
          
          <mat-error style="font-family: Montserrat; color: var(--mon-red);">
            {{errorConfirm}}
          </mat-error>

          <button  style="margin-top: 1em;" [disabled]="disabledConfirm" mat-button class="confirm-button" type="submit">
            Confirm
          </button>
        </div>
      </form>
    }@else {
      <form [formGroup]="formRegister" (submit)="register()" method="post">
        <div style="display: flex; flex-wrap: wrap; justify-content: space-between; gap: 1em;">
          <mat-form-field style="width: 48%; flex-grow:1;">
            <mat-label>First name</mat-label>
            <input formControlName="name" type="text" matInput [readonly]="profile" />
            @if(formRegister.get('name')?.hasError){
              <mat-error style="font-family: Montserrat;">
                {{getError('name')}}
              </mat-error>
            }
            
          </mat-form-field>
          <mat-form-field style="width: 48%; flex-grow:1;">
            <mat-label>Last name</mat-label>
            <input formControlName="surname" type="text" matInput [readonly]="profile" />
            @if(formRegister.get('surname')?.hasError){
              <mat-error style="font-family: Montserrat;">
                {{getError('surname')}}
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div style="width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between; gap: 1em;">
          
          @if( !fastBookingMode || fastBookingMode != 'payment'){
          <div style="width: 48%; min-width: 183px; flex-grow:1;">
            
              @if(profile){
                <mat-form-field style="width: 100%;">
                  <mat-label>Birth date</mat-label>
                  <input style="cursor: default;" formControlName="birthdate" matInput [readonly]="profile">
                  <mat-hint style="font-family: Montserrat;">{{getHint()}}</mat-hint>
                </mat-form-field>
              }@else {
                
                  <mat-form-field style="width: 100%;">
                    <mat-label (click)="picker.open()">Birth date</mat-label>
                    <input readonly (click)="picker.open()" style="cursor: default;" formControlName="birthdate" matInput [matDatepicker]="picker" [readonly]="profile">
                    <mat-hint style="font-family: Montserrat;">{{getHint()}}</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker panelClass="my-custom-panel-birth-date" startView="multi-year" #picker></mat-datepicker>
                    @if(formRegister.get('birthdate')?.hasError){
                      <mat-error style="font-family: Montserrat;">
                        {{getError('birthdate')}}
                      </mat-error>
                    }
                  </mat-form-field>
                
              }
            
            
              <mat-form-field class="gender-form-field" style="width: 100%; margin-top: 0.5em;">
                <input formControlName="gender" matInput hidden [readonly]="profile">
                <mat-button-toggle-group formControlName="gender" 
                style="width: 100%;  border-radius: 4px; border-color: white; background-color: var(--mon-register-color);"
                name="gender" aria-label="Gender">
                  <mat-button-toggle [disabled]="profile" style="width: 50%;" [ngClass]="{'active-gender':formRegister.get('gender')?.value == GENDER.MALE}" [value]="GENDER.MALE">Male</mat-button-toggle>
                  <mat-button-toggle [disabled]="profile" style="width: 50%;" [ngClass]="{'active-gender':formRegister.get('gender')?.value == GENDER.FEMALE}" [value]="GENDER.FEMALE">Female</mat-button-toggle>
                </mat-button-toggle-group>
                @if(formRegister.get('gender')?.hasError){
                  <mat-error style="font-family: Montserrat;">
                    {{getError('gender')}}
                  </mat-error>
                }
              </mat-form-field>
            
            
          </div>
          }
          <div style="width: 48%; min-width: 183px; flex-grow:1;">

            @if(!fastBookingMode || fastBookingMode != 'payment'){
              @if(!profile || isObject(formRegister.get('nationality')?.value)){
                <mat-select-country class="country-container"
                [error]="getError('nationality')"
                appearance="fill" style="width: 100%;" label="Country" formControlName="nationality" [readonly]="profile">
                </mat-select-country>
               
  
              }@else {
                <mat-form-field style="width: 100%;">
                  <mat-label>Country</mat-label>
                  <input formControlName="nationality" [readonly]="profile" matInput readonly>
                </mat-form-field>
              }
            }
            
            
              
            
            <div style="display: flex; flex-direction: row;">
              <mat-select-country style="width: 50%; transform: translateX(5px);" class="country-container"
              [error]="getError('phone_code')"
              appearance="fill" label="Code" [showCallingCode]="true"  formControlName="phone_code" [readonly]="profile"></mat-select-country>
              <mat-form-field style="width: 100%;">
                <mat-label>Phone</mat-label>
                <input formControlName="phone" type="tel" matInput [readonly]="profile" />
                @if(formRegister.get('phone')?.hasError){
                  <mat-error style="font-family: Montserrat;">
                    {{getError('phone')}}
                  </mat-error>
                }
              </mat-form-field>
            </div>
            
          </div>
        </div>
        @if(!start_complete){
          <mat-form-field style="width: 100%;">
            <mat-label>Email</mat-label>
            <input formControlName="email" type="email" matInput [readonly]="profile" />
            @if(formRegister.get('email')?.hasError){
              <mat-error style="font-family: Montserrat;">
                {{getError('email')}}
              </mat-error>
            }
          </mat-form-field>
        }
        
        @if(!fastBookingMode || fastBookingMode == 'guest'){

            @if(!profile && !start_complete){
              <div style="display: flex; justify-content: space-between; flex-wrap: wrap; gap: 1em;">
                <mat-form-field style="width: 48%; flex-grow:1;">
                  <mat-label>Password</mat-label>
                  <input formControlName="password" type="password" matInput  #psw />
                  @if(psw.type == 'password'){
                    <mat-icon style="cursor: pointer;" (click)="psw.type = 'text';psw2.type = 'text'; "  matIconSuffix>visibility_off</mat-icon>
                  }@else {
                    <mat-icon style="cursor: pointer;" (click)="psw.type = 'password';psw2.type = 'password'; "  matIconSuffix>visibility</mat-icon>
                  }
                  @if(formRegister.get('password')?.hasError){
                    <mat-error style="font-family: Montserrat;">
                      {{getError('password')}}
                    </mat-error>
                  }
                </mat-form-field>
                <mat-form-field style="width: 48%; flex-grow:1;">
                  <mat-label>Repeat Password</mat-label>
                  <input formControlName="repeat_password" type="password" matInput  #psw2 />
                  @if(psw2.type == 'password'){
                    <mat-icon style="cursor: pointer;" (click)="psw2.type = 'text'; psw.type = 'text' "  matIconSuffix>visibility_off</mat-icon>
                  }@else {
                    <mat-icon style="cursor: pointer;" (click)="psw2.type = 'password';psw.type = 'password' "  matIconSuffix>visibility</mat-icon>
                  }
                  @if(formRegister.get('repeat_password')?.hasError){
                    <mat-error style="font-family: Montserrat;">
                      {{getError('repeat_password')}}
                    </mat-error>
                  }
                </mat-form-field>
              </div>
            }
          
          
       
        <mat-form-field class="gender-form-field" style="display: flex; flex-wrap: wrap; gap: 4px; align-items: center; width: 100%; color: #415273;">
          <input formControlName="terms" matInput  hidden/>
          <mat-checkbox formControlName="terms" color="primary">I agree with the</mat-checkbox>
          <!-- <span style="width: fit-content; font-family: Montserrat;">By continuing, you agree to our</span> -->
          <a href="/privacy-notice" target="_blank" style="text-decoration: none; color: #db332a; cursor: pointer; font-family:Montserrat;">
            Privacy Notice
          </a>
          @if(formRegister.get('terms')?.hasError){
            <mat-error style="font-family: Montserrat;">
              {{getError('terms')}}
            </mat-error>
          }
        </mat-form-field>
          <ng-content>
          
          </ng-content>
        
        }
      </form>
    }
    
    

