<div style="display: flex; flex-direction: row; background-color: white;">

  @if(dialogRef){
    <section style="width: 510px; background-position: center center; background-size: cover; background-repeat: no-repeat;
    background-image: url('/assets/images/home/my-other-nest-home-30.jpg');">

    </section>
  }
  <section  style="display: flex; flex-direction: column; width: 100%;">
    <div class="title-modal" mat-dialog-title>

      @if(!(data?.start_complete ?? false)){
        @if(fastBookingMode =='payment'){
          <h4>COMPLETE THE FIELDS</h4>
          <h3 style="margin-bottom: 0.5em;">PAYEE DETAILS</h3>
        }@else if (fastBookingMode == 'guest-payment' ) {
          <h4>REGISTER</h4>
          <h3 style="margin-bottom: 0.5em;">GUEST INFORMATION</h3>
        }@else if(fastBookingMode == 'guest'){
          <h4>REGISTER</h4>
          <h3 style="margin-bottom: 0.5em;">GUEST INFORMATION</h3>
        }
        @else {
          <h4>REGISTER</h4>
          <h3 style="margin-bottom: 0.5em;">JOIN US</h3>
        }
      }@else {

        
        @if(fastBookingMode == 'guest'){
          <h4>PROFILE</h4>
          <h3 style="margin-bottom: 0.5em;">COMPLETE YOUR PROFILE</h3>
        }@else {
          <h4>PROFILE</h4>
          <h3 style="margin-bottom: 0.5em;">GUEST PROFILE</h3>
        }

      }
      
      <mat-progress-bar [mode]="loading ? 'indeterminate' : 'determinate'"></mat-progress-bar>
      @if(dialogRef){
        <button mat-dialog-close type="button" title="Close modal" class="close-button" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      }
    </div>
    <mat-dialog-content>

      @if(emittedStart){
        <app-sing-up-form (logged)="logged.emit(true)"  [fastBookingMode]="fastBookingMode" [start_complete]="(data?.start_complete ?? false) " (emailVerification)="openLogin()" (submiting)="enviarSub($event);" (error_signup)="showError($event)"><span class="error">{{error}}</span>
          <mat-dialog-actions>
            <button [disabled]="loading" type="submit" class="login-button"  mat-button style="font-family: Montserrat;">
              @if(!(data?.start_complete ?? false)){
                Register
              }@else {
                Complete the profile
              }
            </button>
            @if(!(data?.start_complete ?? false)){
              <p style="font-family: Montserrat; color: #415273; margin-bottom: 0; margin-top: 1rem; width: 100%; text-align: center;">Do you already have an account? <a (click)="loginClicked($event)" style="color: #db332a; text-decoration: none; cursor: pointer;font-family: Montserrat;">Log In</a></p>
            }
  
            @if(!(data?.start_complete ?? false)){
              <div style="width: 100%; display: flex; justify-content: center; margin-top: 1em;">
                <div style="width: fit-content;" [id]="id_google"></div>
              </div>
            }
  
          </mat-dialog-actions>
        </app-sing-up-form>
      }@else {
        <app-sing-up-form (logged)="logged.emit(true)" [fastBookingMode]="fastBookingMode" [start_complete]="(data?.start_complete ?? false) " (emailVerification)="openLogin()" (submiting)="enviarSub($event);" (error_signup)="showError($event)"><span class="error">{{error}}</span>
          <mat-dialog-actions>
            <button [disabled]="loading" type="submit" class="login-button"  mat-button style="font-family: Montserrat;">
              @if(!(data?.start_complete ?? false)){
                Register
              }@else {
                Complete the profile
              }
            </button>
            @if(!(data?.start_complete ?? false)){
              <p style="font-family: Montserrat; color: #415273; margin-bottom: 0; margin-top: 1rem; width: 100%; text-align: center;">Do you already have an account? <a (click)="loginClicked($event)" style="color: #db332a; text-decoration: none; cursor: pointer;font-family: Montserrat;">Log In</a></p>
            }
  
            @if(!(data?.start_complete ?? false)){
              <div style="width: 100%; display: flex; justify-content: center; margin-top: 1em;">
                <div style="width: fit-content;" [id]="id_google"></div>
              </div>
            }
  
          </mat-dialog-actions>
        </app-sing-up-form>
      }
      
    </mat-dialog-content>
    
  </section>


</div>
