import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SingUpFormComponent } from "./sing-up-form/sing-up-form.component";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { MatProgressBarModule } from '@angular/material/progress-bar'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UserManager } from '@core/managers/UserManager';
import { RequestManager } from '@core/managers/RequestManager';
import { HeaderService } from '@services/header.service';




@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    MatButtonModule,
    SingUpFormComponent,
    MatIconModule,
    FormsModule,ReactiveFormsModule,
    MatProgressBarModule,
    MatFormFieldModule, MatInputModule
],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent {

  @Output() login_clicked : EventEmitter<boolean> = new EventEmitter();
  @Output() logged : EventEmitter<boolean> = new EventEmitter();
  @Input()profile : boolean = false;
  @Input() fastBooking : boolean = false;
  @Input() fastBookingMode ?: string;
  loading : boolean = false;
  error : string = '';
  id_google : string = 'id_google_register';

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) protected  data : any, 
    private userManager: UserManager, 
    private requestManager : RequestManager,
    private headerService : HeaderService,
    private changes : ChangeDetectorRef,
    @Optional() protected dialogRef: MatDialogRef<RegisterComponent>){

    if(this.dialogRef){
      this.id_google += '_ref';
    }

  }

  emittedStart : boolean = false;
  lmd : any = {
    emit : (logged : boolean) => {
      if(logged && this.fastBooking){
        if(!this.data){
          this.data = {}
        }
        this.data.start_complete = true;
        this.emittedStart = true;
        this.changes.detectChanges();
      }
    }
  }

  ngOnInit(){
    if(this.fastBooking){
      if(!this.data) this.data = {};
      this.data.start_complete = this.userManager.getAuth().isLogged();
      this.changes.detectChanges();
    }
  }

  ngAfterViewInit(){
    if(isPlatformBrowser(this.userManager.getPlatformID())){
      this.userManager.getAuth().buildButton(this.id_google, (credential : any)=>{
        if(credential){
          this.userManager.login({credential : credential,data : this.data, dialogRef : this.dialogRef,headerService : this.headerService,
            requestManager : this.requestManager, logged: this.lmd});
        }
      });
    }
  }

  showError(error : string) {
    this.error = error;
  }
  
  enviarSub(event: any) {
    if(typeof event === 'boolean'){
      this.loading = event;
      return;
    }

    this.loading= true;
    this.userManager.save(event, {
      next: (response)=>{
        this.userManager.getAuth().setUser(event);
        this.dialogRef?.close({completed_login: event});
        this.logged.emit(true);
      },
      finalize : () => {
        this.loading= false;
      }
    })

  }

  openLogin() {
    this.dialogRef.close({login: true})
  }

  loginClicked(event: any) {
    
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }

    if(this.dialogRef){
      this.dialogRef.close({login:true, request : this.data.request});
      return;
    }

    this.login_clicked.emit(true);

  }
}
